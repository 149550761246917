<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle"
        aria-describedby="pulping-facility-table"
      >
        <header-table-pulping />
        <template v-if="getToggleModalAddEditDiscard.loadingTable">
          <loading-table :colspan="10" />
        </template>
        <template v-else-if="listPulping.data && listPulping.data.length > 0">
          <tbody>
            <tr
              v-for="(content, index) in listPulping.data"
              :key="index"
            >
              <td>{{ content.vc_code || '-' }}</td>
              <td>{{ content.facility_name || '-' }}</td>
              <td>{{ content.partner || '-' }}</td>
              <td>{{ content.export_hub?.name || '-' }}</td>
              <td>{{ content.hulling_facility?.facility_name || '-' }}</td>
              <td>
                <div v-if="content.status === 1">
                  <label-status
                    status="active"
                    label="Active"
                    table="true"
                  />
                </div>
                <div v-else-if="content.status === 0">
                  <label-status
                    status="inactive"
                    label="Inactive"
                    table="true"
                  />
                </div>
              </td>
              <td>
                <a
                  uk-tooltip="View"
                  @click.prevent="$router.push({ name: 'PulpingFacilityDetail', params:{ id: content.id }})"
                >
                  <span class="action-details">Details</span>
                </a>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-else>
          <empty-table
            :colspan="10"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderTablePulping from './HeaderTablePulping'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'

export default ({
  components:{
    HeaderTablePulping,
    EmptyTable,
    LoadingTable,
    LabelStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPulping: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  }
})
</script>
