<template>
  <div class="uk-form-stacked">
    <h4 class="uk-heading-line">
      Facility Info
    </h4>
    <div
      v-if="loadingDetail"
      class="uk-flex uk-flex-center uk-flex-middle"
    >
      <div uk-spinner />
    </div>
    <div
      v-else
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              EVC Code
            </label>
            <div class="uk-form-controls">
              <input
                name="evc_code"
                :value="detailExportHub.evc_code || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Region
            </label>
            <div class="uk-form-controls">
              <input
                name="Region"
                :value="detailExportHub.region.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Latitude
            </label>
            <div class="uk-form-controls">
              <input
                name="latitude"
                :value="detailExportHub.latitude || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Address
            </label>
            <div class="uk-form-controls">
              <input
                name="address"
                :value="detailExportHub.address || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Export Hub Name
            </label>
            <div class="uk-form-controls">
              <input
                name="export_hub"
                :value="detailExportHub.name || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Area Manager
            </label>
            <div class="uk-form-controls">
              <input
                name="area_manager"
                :value="detailExportHub.area_manager || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Longitude
            </label>
            <div class="uk-form-controls">
              <input
                name="longitude"
                :value="detailExportHub.longitude || '-'"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Status
            </label>
            <div v-if="detailExportHub.status === 1">
              <label-status
                status="active"
                :label="`${status.ACTIVE}`"
                table="false"
              />
            </div>
            <div v-else-if="detailExportHub.status === 0">
              <label-status
                status="inactive"
                :label="`${status.INACTIVE}`"
                table="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button red uk-margin-right"
        :class="isCanAccessUser('delete', 'EXPORT HUB') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('delete', 'EXPORT HUB')"
        @click="showDeleteConfirmModal"
      >
        <img
          :src="`${images}/icon/ic_delete_red.svg`"
          alt="delete"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Delete
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('edit', 'EXPORT HUB') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('edit', 'EXPORT HUB')"
        @click.prevent="$router.push({ name: 'ExportHubEdit'})"
      >
        <img
          :src="`${images}/icon/ic_edit_white.svg`"
          alt="edit"
          loading="lazy"
          class="uk-margin-small-right"
        >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STATUS } from '@/utils/constant'
import LabelStatus from '@/components/globals/LabelStatus'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    LabelStatus
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    },
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return{
      status: STATUS
    }
  },
  computed: {
    ...mapGetters({
      detailExportHub: 'exportHub/detail_export_hub'
    })
  },
  methods: {
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    showDeleteConfirmModal() {
      this.setToggleModalAddEditDiscard({
        ...this.getToggleModalAddEditDiscard,
        targetModals: open,
        title: 'Delete Confirmation',
        description: 'Are you sure want to delete this data?',
        customTitleBtnOk: 'Delete',
        customTitleBtnCancel: 'Cancel',
        type: 'delete',
        loadingTable: false,
        loadingPopUp: false,
        id: this.id
      })
    }
  }
}
</script>
